
const productsubTitle = "Why Alt Text Generator?";
const productTitle = "Why Alt Text Generator?";

const subTitle = "Key Features";
const title = "Alt Text Generator";

const featureLeftList = [
    {
        iconName: 'icofont-credit-card',
        title: 'Math Alt Extraction',
        desc: 'Alt Text Generator AI algorithm is trained to recognize mathematical symbols and equations in images. When a user uploads a math image, the algorithm generates an alt text description that accurately describes the math content in the image. This feature is particularly useful for educators, researchers, and students who frequently use mathematical images in their work.',
    },
    {
        iconName: 'icofont-light-bulb',
        title: 'Non-Math Alt Extraction',
        desc: 'In addition to math images, Alt Text Generator can also generate alt text descriptions for non-mathematical images, such as photographs, graphics, and illustrations. The AI algorithm analyzes the visual elements of an image and generates a concise and accurate description of its content. This feature is useful for website owners and content creators who want to improve the accessibility of their web pages and provide a more inclusive browsing experience for users with visual impairments.',
    },
]

const featureRightList = [
    {
        iconName: 'icofont-site-map',
        title: 'PDF Images Description',
        desc: 'Alt Text Generator also supports the extraction of alt text from images embedded within PDF documents. Users can upload their PDF documents to the platform and get alt text descriptions generated for all the images in the document. This feature is useful for anyone who needs to make their PDF documents more accessible, such as publishers, educators, and researchers. Overall, these key features make Alt Text Generator a versatile and valuable tool for anyone who wants to improve the accessibility of their web pages or documents, especially for people with visual impairments.',
    },
]



const alttextgeneratorfeature = () => {
    return (
        <>
            <section className="feature-section style-2 padding-tb pb-0">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle yellow-color">{productsubTitle}</span>
                        <h2 className="title">{productTitle}</h2>
                    </div>
                    <div className="easy-convert mb-5">
                        <div className="row g-4 row-cols-1 row-cols-lg-2">
                            <div className="col">
                                <div className="px-4">
                                    <h4>What is Alt Text Generator?</h4>
                                    <p>Alt Text Generator is an online application that automatically generates alternative text descriptions for both mathematical and non-mathematical images using cloud-based artificial intelligence technology.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>Why is Alt Text Important?</h4>
                                    <p>Alternative text, or "alt text," is a textual description added to an image to help visually impaired users understand its content. Alt text is an essential component of web accessibility and is required by law in many countries.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>How Does Alt Text Generator Work?</h4>
                                    <p>The AI algorithm used in Alt Text Generator analyzes the visual elements of an image to generate a concise and accurate description of its content. The application is user-friendly and easy to use, allowing users to upload their images and get instant alt text descriptions.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>What are the Benefits of Alt Text Generator? </h4>
                                    <p>Alt Text Generator helps website owners and content creators improve the accessibility of their web pages, making them more inclusive for people with disabilities. The application is time-efficient and can generate alt text descriptions at scale.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>How Does Alt Text Generator Help with Web Accessibility?</h4>
                                    <p>By providing alt text descriptions for images, visually impaired users can better understand the content on a web page, making it more inclusive for people with disabilities. Alt Text Generator is an innovative tool that helps website owners and content creators meet the accessibility requirements of their audience.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>Who Can Benefit from Alt Text Generator? </h4>
                                    <p>Alt Text Generator can benefit website owners and content creators, as well as visually impaired users who rely on alternative text descriptions to understand image content.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="feature-section style-2 padding-tb pb-0">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle yellow-color">{subTitle}</span>
                        <h2 className="title">{title}</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center align-items-center">
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-0">
                                <div className="left text-lg-end">
                                    {featureLeftList.map((val, i) => (
                                        <div className="feature-item" key={i}>
                                            <div className="feature-inner flex-lg-row-reverse">
                                                <div className="feature-icon">
                                                    <i className={val.iconName}></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-2">
                                <div className="right">
                                    {featureRightList.map((val, i) => (
                                        <div className="feature-item" key={i}>
                                            <div className="feature-inner">
                                                <div className="feature-icon">
                                                    <i className={val.iconName}></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-1">
                                <div className="feature-thumb">
                                    <div className="abs-thumb">
                                        <img src="assets/images/feature/alt_genterator.png" alt="education" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default alttextgeneratorfeature;