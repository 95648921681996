import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import ImathicatHeader from "../component/layout/imathicaHeader";
import Imathicafeature from "../component/section/imathicafeature";

// import React from 'react';

import React from 'react';

const Imathica = () => {
    return (
        <Fragment>
            <Header />
            <ImathicatHeader />
            <Imathicafeature />
            <Footer />
        </Fragment>
    );
}

export default Imathica;