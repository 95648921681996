import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import ServicesPageHeader from "../component/layout/servicespageheader";
import PdfConversionFeature from "../component/section/pdfConversionFeature";

const pdfConversion = () => {
    return (
        <Fragment>
            <Header />
            <ServicesPageHeader title={'Pdf Manipulations'} curPage={'Pdf Manipulations'} />
            <PdfConversionFeature />
            <Footer />
        </Fragment>
    );
}

export default pdfConversion;