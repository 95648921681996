
const productsubTitle = "Why Document Validator?";
const productTitle = "Document Validator?";

const subTitle = "Key Features";
const title = "Document Validator";

const featureLeftList = [
    {
        iconName: 'icofont-credit-card',
        title: 'PDF Validator',
        desc: 'A PDF Validator checks the file structure, metadata, fonts, images, and links of a PDF document to ensure it meets industry standards. It is essential for organizations working with large volumes of PDF files to ensure their documents are accurate, reliable, and can be accessed by anyone who needs to.',
    },
]

const featureRightList = [
    {
        iconName: 'icofont-site-map',
        title: 'EPub Validator',
        desc: 'An EPub Validator ensures the file structure, content, and metadata of an EPub document are correct and conform to industry standards. It checks for formatting, broken links, missing files, and incomplete metadata to ensure the document is readable on multiple devices. EPub validation is crucial for publishers and authors to produce high-quality ebooks.',
    },
]



const documentValidatorFeature = () => {
    return (

        <>
            <section className="feature-section style-2 padding-tb pb-0">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle yellow-color">{productsubTitle}</span>
                        <h2 className="title">{productTitle}</h2>
                    </div>
                    <div className="easy-convert mb-5">
                        <div className="row g-4 row-cols-1 row-cols-lg-2">
                            <div className="col">
                                <div className="px-4">
                                    <h4>What is Document Validator?</h4>
                                    <p>Document Validator is a software application that allows users to validate various types of documents, including PDF, ePub, CBZ, and others.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>Why is Document Validation important?</h4>
                                    <p>Document validation ensures that a document is complete, accurate, and meets specific standards or requirements. It helps to ensure that the document can be read and understood by others and is fit for its intended purpose.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>How does Document Validator work?</h4>
                                    <p>Document Validator uses various techniques and algorithms to validate documents, including checking the file structure, identifying errors, and comparing the document against industry standards and regulations.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>What types of documents can be validated using Document Validator?</h4>
                                    <p>Document Validator can validate various types of documents, including PDF, ePub, CBZ, and others. It can also validate documents that contain digital signatures and other security features.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>What are the benefits of using Document Validator?</h4>
                                    <p>Using Document Validator can help to improve the quality and accuracy of documents, reduce errors and inconsistencies, and ensure compliance with industry standards and regulations. It can also save time and reduce costs by automating the document validation process.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>Who can benefit from using Document Validator? </h4>
                                    <p>Document Validator can be used by a wide range of professionals, including authors, publishers, editors, and anyone else who needs to validate documents. It can also be useful for organizations that need to ensure compliance with specific regulations or standards.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="feature-section style-2 padding-tb pb-0">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle yellow-color">{subTitle}</span>
                        <h2 className="title">{title}</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center align-items-center">
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-0">
                                <div className="left text-lg-end">
                                    {featureLeftList.map((val, i) => (
                                        <div className="feature-item" key={i}>
                                            <div className="feature-inner flex-lg-row-reverse">
                                                <div className="feature-icon">
                                                    <i className={val.iconName}></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-2">
                                <div className="right">
                                    {featureRightList.map((val, i) => (
                                        <div className="feature-item" key={i}>
                                            <div className="feature-inner">
                                                <div className="feature-icon">
                                                    <i className={val.iconName}></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-1">
                                <div className="feature-thumb">
                                    <div className="abs-thumb">
                                        <img src="assets/images/feature/10.png" alt="education" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default documentValidatorFeature;