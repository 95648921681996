import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import DocumentValidatorHeader from "../component/layout/documentValidatorHeader";
import DocumentValidatorFeature from "../component/section/document-validator-feature";

// import React from 'react';
const Documentvalidator = () => {
    return (
        <Fragment>
            <Header />
            <DocumentValidatorHeader />
            <DocumentValidatorFeature />
            <Footer />
        </Fragment>
    );
}
 export default Documentvalidator;