import React, { Fragment } from 'react'
import ProductHeader from '../../component/layout/productHeader'
import Header from '../../component/layout/header'
import ProductFeature from '../../component/section/productfeature'
import Footer from '../../component/layout/footer';


const headertitle = "ComixFlip";
const headerDesc = "Comixflip, an innovative comic reader app, offers an immersive and customizable reading experience. Users enjoy one-page and two-page views, personalized light/dark modes, and Multilingual Text-to-Speech for accessibility. The cutting-edge auto-reading mode enhances the reading experience, providing fluidity and advancement.";

const productsubTitle = "WHY COMIXFLIP?";
const productTitle = "What makes ComixFlip a preferred choice among readers?";

const subTitle = "Key Features";
const title = "";

const featureLeftList = [
    {
        iconName: 'icofont-credit-card',
        title: 'Optimized Viewing Options',
        desc: 'One-page view and two-page view for versatile comic reading experiences.',
    },
    {
        iconName: 'icofont-light-bulb',
        title: 'Personalized Visual Preferences',
        desc: 'Light mode and dark mode settings cater to individualized viewing preferences.',
    },
    {
        iconName: 'icofont-graduate',
        title: 'Multilingual Accessibility',
        desc: 'Text-to-Speech functionality supports multiple languages, enhancing inclusivity in reading.',
    },
   
]

const featureRightList = [
    {
        iconName: 'icofont-paper-plane',
        title: 'Seamless Comic Consumption',
        desc: 'Advanced auto-reading mode for a smooth and uninterrupted comic browsing experience.',
    },
    {
        iconName: 'icofont-paper-plane',
        title: 'Cross-Platform Compatibility',
        desc: 'Ensure compatibility with various devices and operating systems for a versatile and accessible reading experience.',
    },
]


const productMultipleDesc = [
    {
        title: 'Adaptive Viewing Options',
        desc: "ComixFlip provides one-page and two-page views, accommodating varied reading preferences."
    },
    {
        title: 'Personalized Comfort',
        desc: 'With light and dark mode settings, readers can customize their viewing experience for optimal comfort.'
    },
    {
        title: 'Multilingual Accessibility',
        desc: "ComixFlip's Text-to-Speech and auto-reading features support multiple languages, offering a versatile and inclusive platform."
    },
    {
        title: 'Innovative and User-Friendly Design',
        desc: "The app's intuitive navigation, customizable fonts, and interactive extras create a seamless and enjoyable comic reading experience, making ComixFlip a preferred choice among readers."
    },
]

// For Babblenex
// const productMultipleDesc = [
//     {
//         title: 'Advanced Document Insight',
//         desc: "The chatbot's capability to extract critical insights from a variety of documents sets it apart, providing users with valuable and actionable information."
//     },
//     {
//         title: 'Image Insight for Visual Interaction',
//         desc: 'The incorporation of Image Insight enables users to engage with the chatbot through image-based interactions, fostering a more dynamic and intuitive user experience.'
//     },
//     {
//         title: 'Multilingual Competence with Language Insight',
//         desc: "The chatbot's proficiency in understanding and processing multiple languages ensures seamless communication, accommodating diverse user preferences and needs."
//     },
//     {
//         title: 'Versatile and Beyond Traditional Interfaces',
//         desc: 'Going beyond conventional conversational interfaces, the AI-Based chatbot offers a sophisticated solution, making it a preferred choice for users seeking advanced functionalities and a comprehensive user experience.'
//     },
// ]


const index = () => {
    return (
        <Fragment>
            <Header />
            <ProductHeader 
                imageName={"comixflip.png"}
                headerDesc={headerDesc} 
                headertitle={headertitle} 
            />
            <ProductFeature
                imageKeyFeatures={"3.png"}
                featureRightList={featureRightList}
                featureLeftList={featureLeftList}
                productsubTitle={productsubTitle}
                productTitle={productTitle}
                subTitle={subTitle}
                title={title}
                productMultipleDesc={productMultipleDesc}
            />
            <Footer />
        </Fragment>
    )
}

export default index