
import {BrowserRouter, Routes, Route } from "react-router-dom";
import 'swiper/css';


import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import AboutPage from "./page/about";
import BlogPage from "./page/blog";
import BlogPageTwo from "./page/blog-2";
import BlogPageThree from "./page/blog-3";
import BlogSingle from "./page/blog-single";
import CartPage from "./page/cart-page";
import ContactPage from "./page/contact";
import CoursePage from "./page/course";
import CourseSingle from "./page/course-single";
import CourseView from "./page/course-view";
import ForgetPass from "./page/forgetpass";
import Home from "./page/home";
import InstructorPage from "./page/instructor";
import LoginPage from "./page/login";
import SearchNone from "./page/search-none";
import SearchPage from "./page/search-page";
import ShopPage from "./page/shop";
import ShopDetails from "./page/shop-single";
import SignupPage from "./page/signup";
import TeamPage from "./page/team";
import TeamSingle from "./page/team-single";
import Product from './page/product';

import Imathica from './page/imathica';
import EasyConvert from './page/easyConvert';
import Alttextgenerator from './page/alttextgenerator';
import Documentvalidator from './page/document-validator';
import PdfConversion from "./page/pdf-conversion";
import EbookConversion from "./page/ebook-conversion";
import Webaccessibility from "./page/web-accessibility";

import Comixflip from "./page/comixflip/index";
import BabbleNex from "./page/babblenex/index";


function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="pdf-conversion" element={<PdfConversion />} />
				<Route path="ebook-conversion" element={<EbookConversion />} />
				<Route path="web-accessibility" element={<Webaccessibility />} />
				<Route path="course" element={<CoursePage />} />
				<Route path="product" element={<Product />} />
				<Route path="imathica" element={<Imathica />} />
				<Route path="easyConvert" element={<EasyConvert />} />
				<Route path="comixflip" element={<Comixflip/>} />
				<Route path="babblenex" element={<BabbleNex/>} />
				<Route path="document-validator" element={<Documentvalidator />} />
				<Route path="alttextgenerator" element={<Alttextgenerator />} />
				<Route path="course-single" element={<CourseSingle />} />
				<Route path="course-view" element={<CourseView />} />
				<Route path="blog" element={<BlogPage />} />
				<Route path="blog-2" element={<BlogPageTwo />} />
				<Route path="blog-3" element={<BlogPageThree />} />
				<Route path="blog-single" element={<BlogSingle />} />
				<Route path="about" element={<AboutPage />} />
				<Route path="team" element={<TeamPage />} />
				<Route path="team-single" element={<TeamSingle />} />
				<Route path="instructor" element={<InstructorPage />} />
				<Route path="shop" element={<ShopPage />} />
				<Route path="shop-single" element={<ShopDetails />} />
				<Route path="cart-page" element={<CartPage />} />
				<Route path="search-page" element={<SearchPage />} />
				<Route path="search-none" element={<SearchNone />} />
				<Route path="contact" element={<ContactPage />} />
				<Route path="login" element={<LoginPage />} />
				<Route path="signup" element={<SignupPage />} />
				<Route path="forgetpass" element={<ForgetPass />} />
				<Route path="*" element={<ErrorPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
