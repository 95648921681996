


import LottiePlayer from "../section/LottiePlayer";
const subTitle = "Online education";
const title = <h2 className="title"><span className="d-lg-block">We Understand, Create </span> <span className="d-lg-block">and Design Process</span></h2>;
// const desc = "Hello Test"
// let BannerVideo="../../assets/images/lottie/banner1.json";
let BannerVideo2 = "https://assets8.lottiefiles.com/private_files/lf30_u4rzoljr.json";
// let BannerVideo3="https://assets3.lottiefiles.com/packages/lf20_UJNc2t.json";


const shapeList = [
    {
        name: '16M Students Happy',
        link: '#',
        className: 'ccl-shape shape-1',
    },
    {
        name: '130K+ Total Courses',
        link: '#',
        className: 'ccl-shape shape-2',
    },
    {
        name: '89% Successful Students',
        link: '#',
        className: 'ccl-shape shape-3',
    },
    {
        name: '23M+ Learners',
        link: '#',
        className: 'ccl-shape shape-4',
    },
    {
        name: '36+ Languages',
        link: '#',
        className: 'ccl-shape shape-5',
    },
]


const Banner = () => {
    return (
        <section className="banner-section">
            <div className="container">
                <div className="section-wrapper">
                    <div className="row align-items-center">
                        <div className="col-xxl-5 col-xl-6 col-lg-10">
                            <div className="banner-content">
                                <h6 className="subtitle text-uppercase fw-medium">{subTitle}</h6>
                                {title}
                                <p>
                                Nexority Infotech specializes in providing a user-friendly and cost-effective PDF to ePub conversion solution using Nexority Infotech's DIY platform. Targeting publishers, authors, and educators, the service allows quick and easy conversion without specialized skills. Additionally, Nexority offers AI-based products like content extraction, document management and AI powered Chatbot to streamline workflows and enhance efficiency. The company is committed to making digital content creation accessible, affordable, and environmentally friendly.    
                                <br /><br /></p>
                                {/* <p>
                                Nexority Infotech strives to provide a user-friendly, cost-effective solution for PDF to ePub conversion. This can be particularly useful for publishers, authors, and educators who want to create digital versions of their books, documents, or study materials. By using Digital Ocean's DIY solution, users can convert their PDF files into ePub format quickly and easily, without the need for specialized technical skills or expensive software.
                                </p>
                                <p>
                                In addition to their PDF to ePub conversion service, Nexority Infotech also offers several other AI-based products and services, including content extraction, text recognition, and document management solutions. These products are designed to help users streamline their workflows, reduce manual labor, and improve the efficiency of their content-related tasks.
                                </p> */}
                                {/* <p>
                                Overall, Nexority Infotech aims to make digital content creation more accessible and affordable for individuals and organizations of all sizes, while also promoting sustainable and environmentally friendly practices.
                                </p> */}
                            </div>
                        </div>
                        <div className="col-xxl-7 col-xl-6 position-relative">
                            <div className="banner-thumb lottie_container" >
                                <LottiePlayer link={`${BannerVideo2}`} />
                            </div>
                            <div className="all-shapes"></div>
                            <div className="cbs-content-list d-none">
                                <ul className="lab-ul">
                                    {shapeList.map((val, i) => (
                                        <li className={val.className} key={i}><a href={val.link}>{val.name}</a></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner;