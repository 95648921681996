

const ProductFeature = ({featureRightList, featureLeftList, productsubTitle, productTitle, subTitle, title, productMultipleDesc, imageKeyFeatures}) => {
    return (

        <>
            <section className="feature-section style-2 padding-tb pb-0">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle yellow-color">{productsubTitle || ""}</span>
                        <h2 className="title">{productTitle || ""}</h2>
                    </div>
                    <div className="easy-convert mb-5">
                        <div className="row g-4 row-cols-1 row-cols-lg-2">
                            {productMultipleDesc?.map(item => (
                            <div className="col">
                                <div className="px-4">
                                    <h4>{item?.title}</h4>
                                    <p>{item?.desc}</p>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section >

            <section className="feature-section style-2 padding-tb pb-0">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle yellow-color">{subTitle || ""}</span>
                        <h2 className="title">{title || ""}</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center align-items-center">
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-0">
                                <div className="left text-lg-end">
                                    {featureLeftList?.map((val, i) => (
                                        <div className="feature-item" key={i}>
                                            <div className="feature-inner flex-lg-row-reverse">
                                                <div className="feature-icon">
                                                    <i className={val.iconName}></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-2">
                                <div className="right">
                                    {featureRightList?.map((val, i) => (
                                        <div className="feature-item" key={i}>
                                            <div className="feature-inner">
                                                <div className="feature-icon">
                                                    <i className={val.iconName}></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-1">
                                <div className="feature-thumb">
                                    <div className="abs-thumb">
                                        <img src={`assets/images/feature/${imageKeyFeatures}`} alt="education" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section></>
    );
}

export default ProductFeature;