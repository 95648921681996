import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import ProductHeader from "../component/layout/productHeader";
import Productfeature from "../component/section/productfeature";

// import React from 'react';

import React from 'react';

const headertitle = "Easy Convert"
const headerDesc = "A reliable PDF conversion tool that provides authentic and high-quality conversion to ePub formats, ensuring accurate and cost-effective results, with prompt support and a secure platform."


const productsubTitle = "Why Easy Convert?";
const productTitle = "What makes EasyConvert a preferred choice among developers?";

const subTitle = "Key Features";
const title = "High Quality Conversion of PDF files to EPub";

const featureLeftList = [
    {
        iconName: 'icofont-credit-card',
        title: 'Optimised Content',
        desc: 'Easyconvert uses smart methods to process PDF files, which include reducing file size and optimizing rendering performance in the browser.',
    },
    {
        iconName: 'icofont-light-bulb',
        title: 'Content Structure',
        desc: 'Easyconvert currently supports automated extraction of structured content from unstructured PDF documents for reflowable outputs.',
    },
    {
        iconName: 'icofont-graduate',
        title: 'Reflow/Fixed/Image-based',
        desc: 'Easyconvert produces ePubs that are either entirely fixed, in image-based format, or in reflowable format.',
    },
    {
        iconName: 'icofont-paper-plane',
        title: 'Content Accuracy',
        desc: 'The techniques used in extracting the content helps to maintain the content accuracy compared to other market products.',
    },
    {
        iconName: 'icofont-paper-plane',
        title: 'Annotations',
        desc: 'Easyconvert offers the ability to extract all annotation kinds, including Text, Highlight, and Underline, as well as markup annotation types, such as Links, Popups, Video, and Sound.',
    },
]

const featureRightList = [
    {
        iconName: 'icofont-site-map',
        title: 'Smooth Selection',
        desc: 'The optimised content extraction helps provide an improved platform selection experience for users.',
    },
    {
        iconName: 'icofont-users-alt-3',
        title: 'Font Conversion:',
        desc: 'The text in converted documents is real and searchable. Embedded fonts have been completely reorganised and written as web fonts, with several optimisations to adapt codepoint ranges and operate in all web browsers. To save download size, fonts are shared throughout pages.',
    },

    {
        iconName: 'icofont-site-map',
        title: 'Works Anywhere',
        desc: 'Translated documents are compatible with all systems, including Windows, Macintosh, Android, iOS, and Linux.',
    },
    {
        iconName: 'icofont-users-alt-3',
        title: 'Deploy Your Way',
        desc: 'Being a cloud-based licenced programme, Easyconvert may be used from anywhere.',
    },
]

const productMultipleDesc = [
    {
        title: 'True-to-life conversion of PDF files',
        desc: 'EasyConvert offers authentic PDF conversion by providing various formats for converting PDF to ePub. The tool parses the PDF file and creates both static fixed and reflowable ePub versions of the original PDF.'
    },
    {
        title: 'Premium quality output',
        desc: 'At our core, we prioritize the quality of the converted document. We are committed to constantly enhancing the quality to achieve the utmost accuracy in conversion, while keeping the cost as low as possible.'
    },
    {
        title: 'Our support is prompt and reliable',
        desc: 'Encountered any problems with your file conversion? Our dedicated team of engineers is always available to assist you and resolve any issues.'
    },
    {
        title: 'Secure platform',
        desc: 'Easyconvert offers usage-based licensing, which means there are no restrictions on licensing.'
    },
]

const easyConvert = () => {
    return (
        <Fragment>
            <Header />
            <ProductHeader
                imageName={"easyconvert.jpg"}
                headerDesc={headerDesc}
                headertitle={headertitle}
            />
            <Productfeature
                imageKeyFeatures={"pdfconverter_img.png"}
                featureRightList={featureRightList}
                featureLeftList={featureLeftList}
                productsubTitle={productsubTitle}
                productTitle={productTitle}
                subTitle={subTitle}
                title={title}
                productMultipleDesc={productMultipleDesc}
            />
            <Footer />
        </Fragment>
    );
}

export default easyConvert;