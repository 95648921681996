import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import ServicesPageHeader from "../component/layout/servicespageheader";
import EbookConversionFeature from "../component/section/ebookConversionFeature";

const ebookConversion = () => {
    return (
        <Fragment>
            <Header />
            <ServicesPageHeader title={'eBook Conversion'} curPage={'eBook Conversion'} />
            <EbookConversionFeature />
            <Footer />
        </Fragment>
    );
}

export default ebookConversion;