
const productsubTitle = "Why eBook conversion?";
const productTitle = "eBook conversion";

const subTitle = "Key Features";
const title = "eBook conversion";

const featureLeftList = [
    {
        iconName: 'icofont-credit-card',
        title: 'Fixed Layout',
        desc: 'Provides conversion automation from multiple inputs (PDF, Word, XML) with content fidelity, offering word, line, or paragraph level outputs as per requirement.',
    },
    {
        iconName: 'icofont-light-bulb',
        title: 'Reflow Layout',
        desc: 'Offers a semi-automated approach to design reflow output ePub with custom-designed stylesheets, using a zoning process to mark input for identifying semantics and export a validated ePub package with applied custom styles.',
    },
    {
        iconName: 'icofont-graduate',
        title: 'Image Based Solution',
        desc: 'Provides a low-cost and 100% automated solution to convert into fixed layout ePub using image-based technology.',
    }
]

const featureRightList = [
    {
        iconName: 'icofont-site-map',
        title: 'ePub Zoner',
        desc: 'Offers a zoning tool for creating structured content with semantics such as lists, tables, figures, math, inline images, paragraphs, headings, blockquotes, and more, with options to apply accessibility features, separate chapters, create links, and correct reading order.',
    },
    {
        iconName: 'icofont-users-alt-3',
        title: 'Mobi/KF8 Conversion',
        desc: 'Supports KF8 output with advanced features like panel view, popup view, etc., using the internally designed autokf8 application, with inputs from PDF, ePub, XML, Word, etc.',
    },

    {
        iconName: 'icofont-site-map',
        title: 'Content Fidelity and Testing',
        desc: 'Includes content comparison applications for automated content fidelity testing and link checker tools for identifying deadlinks, ensuring quality and accuracy in the converted eBooks.',
    }
]

const webAccessibilityList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'eBook conversion',
        desc: 'The process of converting written content into electronic book formats, enabling accessibility, distribution, interactivity, customization, and cost-effectiveness through automation and consideration of factors like formatting, compatibility, and target audience preferences.',
    }
]

const ebookConversionFeature = () => {
    return (
        <>
            <section className="feature-section style-2 padding-tb pb-0">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle yellow-color">{productsubTitle}</span>
                        <h2 className="title">{productTitle}</h2>
                    </div>

                    <div className="section-wrapper mb-5">
                        {webAccessibilityList.map((val, i) => (
                            <div className="mb-4" key={i}>
                                <div className="d-flex about-page flex-wrap">
                                    <div className="sr-left">
                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                    </div>
                                    <div className="sr-right">
                                        <h5>{val.title}</h5>
                                        <p>{val.desc}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="mb-5">
                        <div className="row g-4 row-cols-1 row-cols-lg-2">
                            <div className="col">
                                <div className="px-4">
                                    <h4>What is eBook conversion?</h4>
                                    <p>eBook conversion is the process of converting written content into electronic book formats like ePub, mobi, or kf8.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>Why should we use eBook conversion</h4>
                                    <p>eBook conversion is beneficial for accessibility, distribution, interactivity, customization, cost-effectiveness, and flexibility.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>What are some approaches for eBook conversion automation?</h4>
                                    <p>eBook conversion automation can be achieved through software-based conversion tools, cloud-based conversion services, or script-based automation.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>What are some inputs that can be converted using eBook conversion automation? </h4>
                                    <p>Inputs that can be converted using eBook conversion automation include PDF documents, Word documents, InDesign files, HTML files, and text files.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>What are some factors to consider for high-quality eBook conversion? </h4>
                                    <p>Factors to consider for high-quality eBook conversion include formatting, images, hyperlinks, compatibility, and usability.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>Which eBook format is better for conversion?</h4>
                                    <p>The choice of eBook format depends on the specific requirements of the target audience and distribution channels, with ePub being a widely accepted format for general eBook distribution, and mobi and kf8 being popular for certain platforms.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="feature-section style-2 padding-tb pb-0">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle yellow-color">{subTitle}</span>
                        <h2 className="title">{title}</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center align-items-center">
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-0">
                                <div className="left text-lg-end">
                                    {featureLeftList.map((val, i) => (
                                        <div className="feature-item" key={i}>
                                            <div className="feature-inner flex-lg-row-reverse">
                                                <div className="feature-icon">
                                                    <i className={val.iconName}></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-2">
                                <div className="right">
                                    {featureRightList.map((val, i) => (
                                        <div className="feature-item" key={i}>
                                            <div className="feature-inner">
                                                <div className="feature-icon">
                                                    <i className={val.iconName}></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-1">
                                <div className="feature-thumb">
                                    <div className="abs-thumb">
                                        <img src="assets/images/feature/10.png" alt="education" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section></>
    );
}

export default ebookConversionFeature;