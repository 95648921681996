
const productsubTitle = "Why Easy Convert?";
const productTitle = "What makes EasyConvert a preferred choice among developers?";

const subTitle = "Key Features";
const title = "Imathica";

const featureLeftList = [
    {
        iconName: 'icofont-credit-card',
        title: 'Image to MathML/LaTeX',
        desc: 'Imathica can recognize and convert mathematical expressions from images to MathML or LaTeX format. This is useful when dealing with handwritten or scanned mathematical expressions that need to be digitized.',
    },
    {
        iconName: 'icofont-light-bulb',
        title: 'Convert LaTeX to MathML and vice versa',
        desc: 'Imathica can also convert mathematical expressions from LaTeX format to MathML format and vice versa. This is useful when working with different platforms or software that use different formats.',
    },
    {
        iconName: 'icofont-graduate',
        title: 'Office Docs to Math',
        desc: 'Imathica can extract mathematical expressions from Microsoft Office documents such as Word, Excel, and PowerPoint, and convert them to MathML or LaTeX format. This makes it easier to work with mathematical expressions in these documents, especially when dealing with large volumes of data.',
    },
]

const featureRightList = [
    {
        iconName: 'icofont-site-map',
        title: 'MathML to Image',
        desc: 'Imathica can also convert MathML expressions to image formats such as PNG, JPG, or SVG. This is useful when the output needs to be visualized, such as in presentations or on the web.',
    },
    {
        iconName: 'icofont-users-alt-3',
        title: 'MathML/LaTeX to PS/PDF',
        desc: 'Imathica can convert MathML or LaTeX expressions to PostScript (PS) or Portable Document Format (PDF) formats. This is useful when preparing documents for printing or sharing.',
    },

    {
        iconName: 'icofont-site-map',
        title: 'Math Identifier',
        desc: 'Imathica math identifier can recognize and classify mathematical expressions into different categories such as equations, functions, integrals, derivatives, and matrices. This makes it easier to work with mathematical expressions by allowing users to quickly identify and process different types of expressions.',
    },
]

const imathicafeature = () => {
    return (
        <>
            <section className="feature-section style-2 padding-tb pb-0">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle yellow-color">{productsubTitle}</span>
                        <h2 className="title">{productTitle}</h2>
                    </div>
                    <div className="easy-convert mb-5">
                        <div className="row g-4 row-cols-1 row-cols-lg-2">
                            <div className="col">
                                <div className="px-4">
                                    <h4>Automated math identification</h4>
                                    <p>Imathica can recognize and identify various types of mathematical expressions such as equations, functions, integrals, derivatives, and matrices.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>Conversion</h4>
                                    <p>Imathica can convert mathematical expressions from one format to another. For example, it can convert an equation from standard form to slope-intercept form or convert a decimal number to a fraction.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>Transformation</h4>
                                    <p>Imathica can transform mathematical expressions by applying various mathematical operations. For example, it can simplify algebraic expressions, factor polynomials, or solve equations.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>Efficiency</h4>
                                    <p>Imathica can process large volumes of mathematical expressions quickly and accurately. This makes it ideal for automating repetitive mathematical tasks or processing large datasets.</p>
                                </div>
                            </div>

                            <div className="col">
                                <div className="px-4">
                                    <h4>Ease of use</h4>
                                    <p>Imathica is designed to be easy to use, even for users with limited mathematical knowledge. Its user-friendly interface and one-click processing make it accessible to a wide range of users.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="feature-section style-2 padding-tb pb-0">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle yellow-color">{subTitle}</span>
                        <h2 className="title">{title}</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center align-items-center">
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-0">
                                <div className="left text-lg-end">
                                    {featureLeftList.map((val, i) => (
                                        <div className="feature-item" key={i}>
                                            <div className="feature-inner flex-lg-row-reverse">
                                                <div className="feature-icon">
                                                    <i className={val.iconName}></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-2">
                                <div className="right">
                                    {featureRightList.map((val, i) => (
                                        <div className="feature-item" key={i}>
                                            <div className="feature-inner">
                                                <div className="feature-icon">
                                                    <i className={val.iconName}></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-1">
                                <div className="feature-thumb">
                                    <div className="abs-thumb">
                                        <img src="assets/images/feature/2.png" alt="education" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default imathicafeature;