
const subTitle = "";
const title = "In what ways do we stand out?";
// const desc = "Free online conversion solutions have limitations on file types, size, and number of conversions. They may lack advanced features for complex conversions. Paid software offers wider features, flexibility, and accuracy. They handle complex file types and have batch conversion and OCR. Paid software is better for accurate and frequent conversions.";

const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Pay As You Go',
        desc: 'Our pricing is per page/item with no minimum amount required, providing flexibility for your conversion needs.',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'End to End EBook Designing',
        desc: 'Offering end-to-end eBook design, conversion, and production services worldwide. We collaborate closely with authors, self-publishers, and businesses to ensure premium quality without compromise.',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Academic and Professional EBooks Designing',
        desc: 'We provide diverse services, including academic/professional eBooks, university/library eBooks, government, banking, finance, digital magazines, and more. Alongside eBook services, Digital Ocean Publishing Solutions offers competitive web design, development, and digital marketing services for businesses.',
    },
]


const About = () => {
    return (
        <div className="about-section">
            <div className="container">
                <div className="row justify-content-center row-cols-xl-1 row-cols-1 align-items-center flex-row-reverse">
                    <div className="col">
                        <div className="about-right pt-sm-5 mt-5">
                            <div className="section-header text-center">
                                {/* <span className="subtitle">{subTitle}</span> */}
                                <h2 className="title">{title}</h2>
                                <p>{`Free online conversion tools may have limitations in terms of supported file types, size constraints, and volume restrictions for conversions. These tools often lack advanced features required for intricate tasks such as maintaining formatting, image quality, and accurate language translation. In contrast, Nexority Infotech's paid conversion software offers enhanced flexibility and advanced features, including batch conversion and OCR capabilities. Tailored for businesses and individuals with frequent or specialized conversion needs, this solution provides superior accuracy and functionality. While free tools serve basic needs, Nexority Infotech's paid software is recommended for complex and regular conversion requirements, ensuring a professional and efficient approach to document processing.`}</p>
                                {/* <p>Free online conversion solutions often have limitations in terms of the file types that they can handle, the size of files they can convert, and the number of conversions that can be performed in each period. Additionally, these free solutions may not have the advanced features necessary for more complex conversions such as preserving the formatting of the original document, maintaining image quality, or accurately translating text written in different languages.</p>
                                <p>In contrast, paid conversion software typically offers a wider range of features, greater flexibility, and higher accuracy in conversions. These software programs are designed to handle a variety of file types, including those that are more complex or require special formatting. They also often have features such as batch conversion, optical character recognition (OCR), and advanced language translation, which can be useful for businesses or individuals who require frequent or more specialized conversions.</p>
                                <p>Overall, while free online conversion solutions can be useful for basic conversions or one-time use, they may not be suitable for more complex conversions or regular use. Paid conversion software is often the better option for those who require higher accuracy and functionality in their conversions.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-end flex-row-reverse">
                    <div className="col">
                        <div className="about-right pb-5 mb-5">
                            <div className="section-wrapper">
                                <ul className="lab-ul">
                                    {aboutList.map((val, i) => (
                                        <li key={i}>
                                            <div className="sr-left">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="sr-right">
                                                <h5>{val.title}</h5>
                                                <p>{val.desc}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="about-left">
                            <div className="about-thumb">
                                <img src="assets/images/about/01.png" alt="about" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;