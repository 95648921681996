const title = "Alt Text Generator";
const desc = "An online application that uses AI technology to automatically generate alt text descriptions for images, enhancing web accessibility and inclusivity for visually impaired users while assisting website owners and content creators in meeting accessibility requirements.";
// const author = "Rajib Raj";
// const reviewCount = "03 reviews";
const videoLink = "https://www.youtube-nocookie.com/embed/jP649ZHA8Tg";


const categoryList = [
    // {
    //     link: '#',
    //     text: 'Try Free Demo',
    //     className: 'course-cate',
    // }
]

const AltTextGeneratorHeader = () => {
    return (
        <div className="pageheader-section style-2 position-relative" style={{zIndex:1}}>
            <div className="container">
                <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                    <div className="col-lg-7 col-12">
                        <div className="pageheader-thumb">
                            <img src="assets/images/pageheader/alt_text.webp" alt="rajibraj91" className="w-100" />
                            {/* <a href={videoLink} className="video-button popup" target="_blank" rel="noreferrer"><i className="icofont-ui-play"></i></a> */}
                        </div>
                    </div>
                    <div className="col-lg-5 col-12">
                        <div className="pageheader-content">
                            
                            <h2 className="phs-title">{title}</h2>
                            <p className="phs-desc">{desc}</p>
                            <div className="course-category">
                                {categoryList.map((val, i) => (
                                    <a href={val.link} className={val.className} key={i}>{val.text}</a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default AltTextGeneratorHeader;