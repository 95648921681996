
const productsubTitle = "Why PDF Manipulation?";
const productTitle = "Automation in pdf editing and manipulation";

const subTitle = "Key Features";
const title = "High Quality Conversion of PDF files to EPub";

const featureLeftList = [
    {
        iconName: 'icofont-credit-card',
        title: 'Split & Merge',
        desc: 'Allows users to split a single PDF into multiple files or merge multiple PDF files into one, providing flexibility in managing PDF documents.',
    },
    {
        iconName: 'icofont-light-bulb',
        title: 'Fill Forms',
        desc: 'Enables extraction of data from PDF forms or filling out PDF forms, making it convenient for handling form-based information.',
    },
    {
        iconName: 'icofont-graduate',
        title: 'Preflight',
        desc: 'Validates PDF files against the PDF/A-1b standard, ensuring compliance with industry standards for long-term archiving and preservation.',
    },
    {
        iconName: 'icofont-paper-plane',
        title: 'Print',
        desc: 'Provides the ability to print PDF files using the standard Java printing API, making it easy to generate hard copies of PDF documents.',
    },
    {
        iconName: 'icofont-paper-plane',
        title: 'Create PDFs',
        desc: 'Enables the creation of PDF files from scratch, including embedded fonts and images, giving users the ability to generate new PDF content.',
    },
    {
        iconName: 'icofont-paper-plane',
        title: 'Signing',
        desc: 'Allows for digital signing of PDF files, adding a layer of security and authentication to PDF documents.',
    }
]

const featureRightList = [
    {
        iconName: 'icofont-site-map',
        title: 'Edit Metadata',
        desc: 'Provides the ability to edit metadata information in PDF files, allowing users to manage and update document properties and information.',
    },
    {
        iconName: 'icofont-users-alt-3',
        title: 'Crop and Rotate',
        desc: 'Allows for cropping and rotating PDF pages, providing options for adjusting page orientation and content placement.',
    },

    {
        iconName: 'icofont-site-map',
        title: 'Insert and Delete',
        desc: 'Enables insertion and deletion of existing pages in PDF documents, providing flexibility in managing document content.',
    },
    {
        iconName: 'icofont-users-alt-3',
        title: 'Alt Addition',
        desc: 'Provides the ability to add alt text to images in PDF documents using automations, making PDF content accessible for individuals with visual impairments.',
    },
    {
        iconName: 'icofont-site-map',
        title: 'Export Option',
        desc: 'Allows for exporting PDF documents as images, text, or structured content, providing options for sharing and using PDF content in different formats.',
    },
    {
        iconName: 'icofont-users-alt-3',
        title: 'Annotations',
        desc: 'Provides options for editing and exporting annotations in PDF documents, allowing for collaboration and communication within the document.',
    }
]

const webAccessibilityList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'eBook conversion',
        desc: 'The use of software or tools to perform tasks on PDF files automatically, improving productivity, accuracy, consistency, and data security while reducing manual effort and time for large volumes of PDF files.',
    }
]

const PdfConversionFeature = () => {
    return (

        <>
            <section className="feature-section style-2 padding-tb pb-0">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle yellow-color">{productsubTitle}</span>
                        <h2 className="title">{productTitle}</h2>
                    </div>

                    <div className="section-wrapper mb-5">
                        {webAccessibilityList.map((val, i) => (
                            <div className="mb-4" key={i}>
                                <div className="d-flex about-page flex-wrap">
                                    <div className="sr-left">
                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                    </div>
                                    <div className="sr-right">
                                        <h5>{val.title}</h5>
                                        <p>{val.desc}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="mb-5">
                        <div className="row g-4 row-cols-1 row-cols-lg-2">
                            <div className="col">
                                <div className="px-4">
                                    <h4>What is automation in PDF editing and manipulation?</h4>
                                    <p>Automation in PDF editing and manipulation refers to the use of software or tools that can perform various tasks on PDF files automatically, such as cropping, merging, splitting, insertion, deletion, rotation, encryption, and decryption, without requiring manual intervention.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>Why is automation in PDF editing and manipulation better?</h4>
                                    <p>Automation in PDF editing and manipulation is better because it significantly reduces the time and effort required to perform repetitive tasks on PDF files. It eliminates the need for manual editing and manipulation, which can be time-consuming and error-prone, and allows for consistent and accurate results.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>How does automation in PDF editing and manipulation improve productivity?</h4>
                                    <p>Automation in PDF editing and manipulation improves productivity by streamlining the PDF editing process. It allows for batch processing of multiple PDF files at once, reducing the need for manual processing of individual files. It also eliminates the need for redundant tasks, such as cropping, merging, splitting, insertion, deletion, rotation, encryption, and decryption, which can be automated with software tools.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>How does automation in PDF editing and manipulation enhance accuracy and consistency?</h4>
                                    <p>Automation in PDF editing and manipulation enhances accuracy and consistency by eliminating human errors that may occur during manual editing and manipulation. Automated tools ensure that the same editing and manipulation tasks are performed consistently across multiple PDF files, resulting in accurate and uniform output.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>What are the benefits of using automation in PDF editing and manipulation for large volumes of PDF files? </h4>
                                    <p>Using automation in PDF editing and manipulation is highly beneficial for large volumes of PDF files. It allows for efficient processing of a large number of files in a shorter amount of time, reducing manual effort and increasing productivity. It also ensures consistent editing and manipulation of PDF files, maintaining accuracy and quality across the entire batch.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>How does automation in PDF editing and manipulation enhance data security? </h4>
                                    <p>Automation in PDF editing and manipulation can enhance data security by providing encryption and decryption capabilities. It allows for automated encryption of sensitive PDF files to protect their content from unauthorized access. Similarly, it can automate the decryption process to access encrypted PDF files securely.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="feature-section style-2 padding-tb pb-0">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle yellow-color">{subTitle}</span>
                        <h2 className="title">{title}</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center align-items-center">
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-0">
                                <div className="left text-lg-end">
                                    {featureLeftList.map((val, i) => (
                                        <div className="feature-item" key={i}>
                                            <div className="feature-inner flex-lg-row-reverse">
                                                <div className="feature-icon">
                                                    <i className={val.iconName}></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-2">
                                <div className="right">
                                    {featureRightList.map((val, i) => (
                                        <div className="feature-item" key={i}>
                                            <div className="feature-inner">
                                                <div className="feature-icon">
                                                    <i className={val.iconName}></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-1">
                                <div className="feature-thumb">
                                    <div className="abs-thumb">
                                        <img src="assets/images/feature/10.png" alt="education" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section></>
    );
}

export default PdfConversionFeature;