
const productsubTitle = "Why PDF/EPUB/WEB Accessibility?";
const productTitle = "PDF/EPUB/WEB Accessibility";

const subTitle = "Key Features";
const title = "PDF/EPUB/WEB Accessibility";

const featureLeftList = [
    {
        iconName: 'icofont-credit-card',
        title: 'Automated PDF Remediation',
        desc: 'Our automated approach allows for easy conversion of existing PDF files into high-quality accessible PDF/UA format, with auto-tagging of important structures like text, images, tables, headings, lists, and reading order. This saves time and reduces remediation costs through batch processing.',
    }
]

const featureRightList = [
    {
        iconName: 'icofont-site-map',
        title: 'Automated Data Extraction from PDF',
        desc: 'Our machine learning-based algorithm allows for easy extraction of data from PDF files, including logical structures such as texts, headings, images, tables, headers/footers, and lists. This data can be converted into HTML, CSV, JSON, or XML formats for further use, simplifying the process of extracting valuable information from PDF files.',
    }
]

const webAccessibilityList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Automated content ingestion',
        desc: 'The automated process of importing and converting various content formats into eLearning platforms, saving time and effort while ensuring consistency, accuracy, and compatibility with specific platforms.',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'PDF/EPUB/WEB accessibility testing',
        desc: 'The process of testing eBooks against accessibility standards using screen reader software and ACE checker to ensure inclusivity and compliance with accessibility laws, improving user experience and expanding content reach.',
    }
]

const ebookConversionFeature = () => {
    return (
        <>
            <section className="feature-section style-2 padding-tb pb-0">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle yellow-color">{productsubTitle}</span>
                        <h2 className="title">{productTitle}</h2>
                    </div>

                    <div className="section-wrapper mb-5">
                        {webAccessibilityList.map((val, i) => (
                            <div className="mb-4" key={i}>
                                <div className="d-flex about-page flex-wrap">
                                    <div className="sr-left">
                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                    </div>
                                    <div className="sr-right">
                                        <h5>{val.title}</h5>
                                        <p>{val.desc}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="mb-5">
                        <div className="row g-4 row-cols-1 row-cols-lg-2">
                            <div className="col">
                                <div className="px-4">
                                    <h4>What is PDF/EPUB/WEB accessibility testing?</h4>
                                    <p>It involves testing eBooks against accessibility standards such as WCAG (Web Content Accessibility Guidelines) using screen reader software like NVDA, JAWS, etc., and using ACE checker to identify and remediate accessibility issues.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>Why is PDF/EPUB/WEB accessibility testing important?</h4>
                                    <p>Accessibility testing ensures that eBooks are accessible to people with disabilities, allowing them to access and interact with the content effectively, regardless of their disabilities or assistive technologies.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>How do you test eBooks for accessibility?</h4>
                                    <p>eBooks are tested using screen reader software like NVDA, JAWS, etc., which simulate the experience of users with visual impairments, and using ACE checker to identify and remediate accessibility issues against WCAG standards.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>What are the benefits of making eBooks accessible?</h4>
                                    <p>Making eBooks accessible ensures inclusivity, compliance with accessibility laws, improves user experience for all users, enhances brand reputation, and expands the reach of the content to a wider audience.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>Can eBooks be remediated for accessibility?</h4>
                                    <p>Yes, eBooks can be remediated for accessibility by addressing identified issues such as proper use of headings, alternative text for images, proper labeling for form fields, logical reading order, and other accessibility best practices.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="px-4">
                                    <h4>Why should I use eBook conversion services that include accessibility testing?</h4>
                                    <p>eBook conversion services that include accessibility testing ensure that the resulting eBooks are compliant with accessibility standards, making them usable by people with disabilities and ensuring inclusivity in content distribution.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="feature-section style-2 padding-tb pb-0">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle yellow-color">{subTitle}</span>
                        <h2 className="title">{title}</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center align-items-center">
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-0">
                                <div className="left text-lg-end">
                                    {featureLeftList.map((val, i) => (
                                        <div className="feature-item" key={i}>
                                            <div className="feature-inner flex-lg-row-reverse">
                                                <div className="feature-icon">
                                                    <i className={val.iconName}></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-2">
                                <div className="right">
                                    {featureRightList.map((val, i) => (
                                        <div className="feature-item" key={i}>
                                            <div className="feature-inner">
                                                <div className="feature-icon">
                                                    <i className={val.iconName}></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 order-lg-1">
                                <div className="feature-thumb">
                                    <div className="abs-thumb">
                                        <img src="assets/images/feature/10.png" alt="education" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section></>
    );
}

export default ebookConversionFeature;