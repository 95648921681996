
import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import About from "../component/section/about";
// import Achievement from "../component/section/achievement";
import Banner from "../component/section/banner";
// import Blog from "../component/section/blog";
import Student from "../component/section/student";


const Home = () => {
    return (
        <Fragment>
            <Header />
            <Banner />
            <About />
            <Student />
            <Footer />
        </Fragment>
    );
}
 

export default Home;