import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import AltTextGeneratorHeader from "../component/layout/altTextGeneratorHeader";
import Alttextgeneratorfeature from "../component/section/alttextgeneratorfeature";


const AltTextGenerator = () => {
    return (
        <Fragment>
            <Header />
            <AltTextGeneratorHeader />
            <Alttextgeneratorfeature />
            <Footer />
        </Fragment>
    );
}

export default AltTextGenerator;
