import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const phoneNumber = "+91 - 78285 67519";
const address = "C-84 F, 05 UG Hindon Vihar, Noida, India";
const email = "info@nexorityinfotech.com";


let socialList = [
    {
        iconName: 'icofont-linkedin',
        siteLink: '#',
    },
    {
        iconName: 'icofont-twitter',
        siteLink: '#',
    },
    {
        iconName: 'icofont-skype',
        siteLink: '#',
    }
]

const Header = () => {
    const [menuToggle, setMenuToggle] = useState(false);
    const [socialToggle, setSocialToggle] = useState(false);
    const [headerFiexd, setHeaderFiexd] = useState(false);

    window.addEventListener("scroll", () => {
        if (window.scrollY > 200) {
            setHeaderFiexd(true);
        } else {
            setHeaderFiexd(false);
        }
    });

    return (
        <header className={`header-section ${headerFiexd ? "header-fixed fadeInUp" : ""}`}>
            <div className={`header-top ${socialToggle ? "open" : ""}`}>
                <div className="container">
                    <div className="header-top-area">
                        <ul className="lab-ul left">
                            <li><i className="icofont-ui-call"></i> <span>{phoneNumber}</span></li>
                            <li><i className="icofont-location-pin"></i> {address}</li>
                            <li><i className="icofont-location-mail"></i> {email}</li>
                        </ul>
                        <ul className="lab-ul social-icons d-flex align-items-center">
                            <li><p>Find us on : </p></li>
                            {socialList.map((val, i) => (
                                <li key={i}><a href={val.siteLink}><i className={val.iconName}></i></a></li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="header-bottom">
                <div className="container">
                    <div className="header-wrapper">
                        <div className="logo" >
                            <Link style={{display: 'flex', flexDirection: 'row'}} to="/"><img style={{height: 50, width: 45}} src={require("../../assets/images/logo/logo-icon.png")} alt="logo" /><h3 style={{paddingTop: 13, paddingLeft: 10}}>Nexority<span style={{color: "#ee4634", fontWeight: 400}}>Infotech</span></h3></Link>
                        </div>
                        <div className="menu-area">
                            <div className="menu">
                                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><NavLink to="/about">About Us</NavLink></li>
                                     <li className="menu-item-has-children">
                                        <a href="/" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Products</a>
                                        <ul className="lab-ul dropdown-menu">
                                            <li><NavLink to="/babblenex">BabbleNex</NavLink></li>
                                            <li><NavLink to="/comixflip">Comixflip</NavLink></li>
                                            <li><NavLink to="/easyConvert">Easy Convert</NavLink></li>
                                            <li><NavLink to="/imathica">Imathica</NavLink></li>
                                            <li><NavLink to="/alttextgenerator">Alt text generator</NavLink></li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <a href="/" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Services</a>
                                        <ul className="lab-ul dropdown-menu">
                                            <li><NavLink to="/pdf-conversion">pdf manipulation</NavLink></li>
                                            <li><NavLink to="/ebook-conversion">ebook conversion</NavLink></li>
                                            <li><NavLink to="/web-accessibility">pdf/epub/web accessibility</NavLink></li>
                                            {/* <li><NavLink to="/content-ingestion">content ingestion</NavLink></li>
                                            <li><NavLink to="/math-conversion">math conversion</NavLink></li>
                                            <li><NavLink to="/read-aloud-solution">read aloud solution</NavLink></li> */}
                                        </ul>
                                    </li>
                                    <li><NavLink to="/contact">Contact</NavLink></li>
                                </ul>
                            </div>

                            <div className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`} onClick={() => setMenuToggle(!menuToggle)}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="ellepsis-bar d-lg-none" onClick={() => setSocialToggle(!socialToggle)}>
                                <i className="icofont-info-square"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;