import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import ProductHeader from "../component/layout/productHeader";
import ProductFeature from "../component/section/productfeature";


const CourseSingle = () => {
    return (
        <Fragment>
            <Header />
            <ProductHeader />
            <ProductFeature/>
            <Footer />
        </Fragment>
    );
}

export default CourseSingle;