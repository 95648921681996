import React, { Fragment } from 'react'
import ProductHeader from '../../component/layout/productHeader'
import Header from '../../component/layout/header'
import ProductFeature from '../../component/section/productfeature'
import Footer from '../../component/layout/footer';


const headertitle = "BabbleNex";
const headerDesc = "The AI-Based Chatbot is a sophisticated solution with advanced capabilities surpassing traditional conversational interfaces. Featuring Document Insight for extracting critical information from diverse documents, Image Insight for image-based interaction, and Language Insight for seamless multilingual communication, it stands as a versatile and powerful tool for efficient data processing and interaction.";

const productsubTitle = "WHY BabbleNex?";
const productTitle = "What makes BabbleNex a preferred choice among users?";

const subTitle = "Key Features";
const title = "";

const featureLeftList = [
    {
        iconName: 'icofont-credit-card',
        title: 'Document Insight',
        desc: 'Extracts critical insights and information from a diverse range of documents, enhancing data processing capabilities.',
    },
    {
        iconName: 'icofont-light-bulb',
        title: 'Image Insight',
        desc: 'Enables image-based interaction and analysis through advanced image recognition technology, expanding the scope of user engagement.',
    },
    {
        iconName: 'icofont-graduate',
        title: 'Language Insight',
        desc: 'Facilitates seamless communication and interaction by effectively understanding and processing various languages, catering to a diverse user base.',
    },
    {
        iconName: 'icofont-graduate',
        title: 'Advanced Conversational Interfaces',
        desc: 'Supports and processes multiple languages, ensuring inclusivity and a user-friendly experience for individuals with different language preferences.',
    },
    {
        iconName: 'icofont-graduate',
        title: 'Multilingual Competence',
        desc: 'Goes beyond traditional chatbot capabilities, providing users with a sophisticated and versatile solution for more comprehensive interactions.',
    },
   
]

const featureRightList = [
    {
        iconName: 'icofont-paper-plane',
        title: 'Dynamic User Experience',
        desc: 'Enhances user engagement with features like Image Insight and advanced conversational interfaces, creating a dynamic and intuitive user experience.',
    },
    {
        iconName: 'icofont-paper-plane',
        title: 'Data Processing Efficiency',
        desc: 'Streamlines data processing by extracting valuable insights from documents, contributing to improved decision-making and information retrieval.',
    },
    {
        iconName: 'icofont-paper-plane',
        title: 'Versatility in Functionality',
        desc: 'Offers a wide range of capabilities, from document analysis to image recognition, making it a versatile solution adaptable to various user needs.',
    },
    {
        iconName: 'icofont-paper-plane',
        title: 'Actionable Insights',
        desc: 'Provides users with actionable information extracted from documents, enhancing the practical utility of the chatbot in decision-making processes.',
    },
    {
        iconName: 'icofont-paper-plane',
        title: 'User-Friendly Design',
        desc: 'Prioritizes ease of use through a well-designed interface, ensuring a positive and accessible experience for users engaging with the chatbot.',
    },
]


const productMultipleDesc = [
    {
        title: 'Advanced Document Insight',
        desc: "The chatbot's capability to extract critical insights from a variety of documents sets it apart, providing users with valuable and actionable information."
    },
    {
        title: 'Image Insight for Visual Interaction',
        desc: 'The incorporation of Image Insight enables users to engage with the chatbot through image-based interactions, fostering a more dynamic and intuitive user experience.'
    },
    {
        title: 'Multilingual Competence with Language Insight',
        desc: "The chatbot's proficiency in understanding and processing multiple languages ensures seamless communication, accommodating diverse user preferences and needs."
    },
    {
        title: 'Versatile and Beyond Traditional Interfaces',
        desc: 'Going beyond conventional conversational interfaces, the AI-Based chatbot offers a sophisticated solution, making it a preferred choice for users seeking advanced functionalities and a comprehensive user experience.'
    },
]


const index = () => {
    return (
        <Fragment>
            <Header />
            <ProductHeader 
                imageName={"babblenex.png"}
                headerDesc={headerDesc} 
                headertitle={headertitle}
            />
            <ProductFeature
                imageKeyFeatures={"4.png"}
                featureRightList={featureRightList}
                featureLeftList={featureLeftList}
                productsubTitle={productsubTitle}
                productTitle={productTitle}
                subTitle={subTitle}
                title={title}
                productMultipleDesc={productMultipleDesc}
            />
            <Footer />
        </Fragment>
    )
}

export default index