
// const author = "Rajib Raj";
// const reviewCount = "03 reviews";
// const videoLink = "https://www.youtube-nocookie.com/embed/jP649ZHA8Tg";


const categoryList = [
    // {
    //     link: '#',
    //     text: 'Try Free Demo',
    //     className: 'course-cate',
    // }
]


const ProductHeader = ({headertitle, headerDesc, imageName}) => {
    return (
        <div className="pageheader-section style-2 position-relative" style={{zIndex:1}}>
            <div className="container">
                <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                    <div className="col-lg-7 col-12">
                        <div className="pageheader-thumb">
                            <img src={`../../assets/images/pageheader/${imageName}`} alt="rajibraj91" className="w-100" />
                        </div>
                    </div>
                    <div className="col-lg-5 col-12">
                        <div className="pageheader-content">
                            
                            <h2 className="phs-title">{headertitle || ""}</h2>
                            <p className="phs-desc">{headerDesc || ""}</p>
                            <div className="course-category">
                                {categoryList.map((val, i) => (
                                    <a href={val.link} className={val.className} key={i}>{val.text}</a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default ProductHeader;