import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import ServicesPageHeader from "../component/layout/servicespageheader";
import WebAccessibilityFeature from "../component/section/webAccessibilityFeature";

import React from 'react';

const webaccessibility = () => {
  return (
    <Fragment>
        <Header />
        <ServicesPageHeader title={'Web Accessibility'} curPage={'Web Accessibility'} />
        <WebAccessibilityFeature />
        <Footer />
    </Fragment>
  );
}

export default webaccessibility;